<template>
  <div class="ManagementCenterH5">
    <div class="Centen">
      <!-- <div class="user_list_details">审核列表</div> -->
      <!-- <van-cell-group>
        <van-cell title="审核列表" label="用户报名后等待管理员审核操作" />
      </van-cell-group> -->
      <div class="h3">已报名比赛（等待管理员审核列表）</div>
      <el-row style="padding-top: 10px">
        <el-col :span="16">
          <el-select
            style="width: 100%"
            v-model="ExamineParams.challenge_id"
            clearable
            size="mini"
            placeholder="请选择审核比赛人员名单"
          >
            <el-option
              v-for="item in MatchListAll"
              :key="item.challenge_id"
              :label="item.challenge_title"
              :value="item.challenge_id"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10" style="padding-top: 10px">
          <el-input
            v-model="ExamineParams.keywords"
            size="mini"
            @keyup.enter.native="getExamineDataList"
            clearable
            placeholder="请输入用户关键字"
          ></el-input>
        </el-col>
        <el-col :span="4" style="padding-top: 10px; padding-left: 10px">
          <el-button type="success" size="mini" @click="getExamineDataList"
            >搜索</el-button
          >
        </el-col>
      </el-row>
      <table>
        <thead>
          <tr>
            <th style="width: 70px">姓名</th>
            <th>学号</th>
            <!-- <th>学号</th> -->
            <!-- <th>学校</th> -->
            <!-- <th>提交时间</th> -->
            <th>学校</th>
            <th style="width: 60px">操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in ExamineDataList" :key="item.challenge_id">
            <td>
              {{ item.realname }}
            </td>
            <td>{{ item.student_num }}</td>
            <!-- <td>{{ item.student_num }}</td> -->
            <!-- <td>{{ item.school_name }}</td> -->
            <!-- <td>{{ item.update_time }}</td> -->
            <td>{{ item.school_name }}</td>
            <td>
              <i
                style="
                  cursor: pointer;
                  padding-left: 10px;
                  font-size: 14px;
                  float: left;
                  color: rgb(24, 144, 255);
                "
                title="审核详情"
                class="el-icon-s-check"
                @click="ExamineDetails(item)"
              ></i>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- <pagination
        style="zoom: 0.49"
        :total="ExamineTotal"
        :page.sync="ExamineParams.pageNum"
        :limit.sync="ExamineParams.pageSize"
        @pagination="getExamineDataList"
      /> -->
	  <van-pagination
			@change="getExamineDataList"
			v-model="ExamineParams.pageNum"
			:total-items="ExamineTotal"
			:show-page-size="5"
		/>
      <div style="height: 100px"></div>
    </div>

    <van-action-sheet
      style="height: 100%"
      v-model="ExamineState"
      title="审核详情"
    >
      <div style="height: 100%" class="content">
        <van-form @submit="onSubmit">
          <van-field
            v-model="ExamineForm.username"
            name="手机号"
			readonly
            label="手机号"
            placeholder="手机号"
          />
          <van-field
            v-model="ExamineForm.realname"
            name="姓名"
			readonly
            label="姓名"
            placeholder="姓名"
          />
          <van-field
            v-model="ExamineForm.nickname"
            name="昵称"
			readonly
            label="昵称"
            placeholder="昵称"
          />
          <van-field
            v-model="ExamineForm.student_num"
            name="学号"
			readonly
            label="学号"
            placeholder="学号"
          />
          <van-field
            v-model="ExamineForm.school_name"
            name="学校"
			readonly
            label="学校"
            placeholder="学校"
          />
          <van-field
            v-model="ExamineForm.specialty"
            name="专业"
			readonly
            label="专业"
            placeholder="专业"
          />
          <van-field
            v-model="ExamineForm.grade"
            name="年级"
			readonly
            label="年级"
            placeholder="年级"
          />
          <van-field
            v-model="ExamineForm.education_background"
            name="学历"
			readonly
            label="学历"
            placeholder="学历"
          />
          <van-field
            v-model="ExamineForm.faculty_adviser"
            name="指导老师"
			readonly
            label="指导老师"
            placeholder="指导老师"
          />
          <van-field
            v-model="ExamineForm.email"
            name="邮箱"
			readonly
            label="邮箱"
            placeholder="邮箱"
          />
          <van-field name="uploader" label="学生证图片">
            <!-- <van-image
              width="100"
              height="100"
			  
              :src="ExamineForm.student_card_path"
            /> -->
			 <template #input>
				<van-uploader v-model="uploader" :deletable="false" :show-upload="false" />
			 </template>
          </van-field>
          <van-field name="picker" :value="reason" placeholder="点击选择审核失败原因" @click="showPicker = true" label="审核失败原因">
            <!-- <template #input>
              <van-radio-group v-model="reason" direction="horizontal">
                <van-radio name="请补充学生证图片">请补充学生证图片</van-radio>
                <van-radio name="请完善个人信息">请完善个人信息</van-radio>
                <van-radio name="其他">其他</van-radio>
              </van-radio-group>
            </template> -->
          </van-field>
		  <van-popup v-model="showPicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="reasonList"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup>
          <div
            style="margin: 16px; display: flex; justify-content: space-between"
          >
            <van-button
              style="width: 40%"
              size="small"
              round
              block
              type="info"
              native-type="submit"
              @click="ConfirmExamine(1)"
              >通过</van-button
            >
            <van-button
              style="width: 40%"
              size="small"
              round
              block
              type="warning"
              native-type="submit"
              @click="ConfirmExamine(0)"
              >不通过</van-button
            >
          </div>
        </van-form>
      </div>
    </van-action-sheet>
    <Tabbar :selected="selected"></Tabbar>
  </div>
</template>

<script>
import Tabbar from "../../components/H5/Tabbar.vue";
import { Notify } from "vant";
import { search_apply_list, check_apply } from "../../api/ManagementCenter.js";
import { MatchList } from "../../api/CompetitionList.js";
export default {
  components: { Tabbar },
  data() {
    return {
      selected: "ManagementCenterH5",
      Height: document.documentElement.clientHeight,
      ExamineParams: {
        keywords: "",
        pageNum: 1,
        pageSize: 10,
        challenge_id: null,
      },
	  uploader:[{
		 url:"", 
	  }],
      ExamineDataList: [],
      ExamineTotal: 0,
      ExamineState: false,
      ExamineForm: {},
      reason: "",
      MatchListAll: [],
      Params: {
        keywords: "",
        pageNum: 1,
        pageSize: 100,
      },
	  showPicker:false,
	  reasonList:[
		  '请补充学生证图片',
		  '请完善个人信息',
		  '学历信息有误',
		  '学校信息有误',
		  '其他'
	  ]
    };
  },
  created() {
    
  },
  mounted(){
    this.getExamineDataList();
    this.getData();
  },
  methods: {
    // 获取审核列表数据
    getExamineDataList() {
      let Params = this.ExamineParams;
      search_apply_list(Params)
        .then((res) => {
          if (res.code == 200) {
            this.ExamineDataList = res.data.list;
            this.ExamineTotal = res.data.total;
          }
        })
        .catch(() => {
          this.ExamineDataList = [];
        });
    },
	onConfirm(value){
		this.reason = value;
      	this.showPicker = false;
	},
    getData() {
      MatchList(this.Params).then((res) => {
        this.MatchListAll = res.data.list;
      });
    },
    ExamineDetails(item) {
      this.ExamineForm = JSON.parse(JSON.stringify(item));
	  this.uploader[0].url = this.ExamineForm.student_card_path
      this.ExamineState = true;
    },
    onSubmit() {},
    // 确认审核
    ConfirmExamine(n) {
      var ParamData = {};
      if (n == 0) {
        if (this.reason) {
          ParamData = {
            tourney_id: this.ExamineForm.tourney_id,
            passed: 0,
            reason: this.reason,
          };
          check_apply(ParamData).then((res) => {
            if (res.code == 200) {
              this.getExamineDataList();
              this.ExamineForm = {};
			  this.ExamineState = false
              Notify({ type: "success", message: res.msg });
            }
          });
        } else {
          Notify({ type: "danger", message: "必须选择审核不通过原因！" });
        }
      } else {
        ParamData = {
          tourney_id: this.ExamineForm.tourney_id,
          passed: 1,
        };
        check_apply(ParamData).then((res) => {
          if (res.code == 200) {
            this.getExamineDataList();
            this.ExamineForm = {};
			this.ExamineState = false
            Notify({ type: "success", message: res.msg });
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// .content {
//   padding: 16px 16px 160px;
//   padding-bottom: 40px;
// }
.ManagementCenterH5 {
  width: 100%;
  height: 100%;
  .Centen {
    width: calc(100% - 20px);
    margin: 0 auto;
    padding-bottom: 44px;
    .user_list_details {
      color: rgb(43, 103, 238);
      font-size: 1.4rem;
      font-weight: 600;
      text-align: center;
      position: relative;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    .h3 {
      color: #2b67ee;
      font-size: 14px;
      font-weight: 600;
      padding: 14px 0;
      span {
        color: red;
        font-size: 12px;
      }
    }
    table {
      font-size: 1em;
      width: 100%;
      background: #fff;
      margin: 1em 0;
      border: 1px solid rgba(34, 36, 38, 0.15);
      -webkit-box-shadow: none;
      box-shadow: none;
      border-radius: 0.28571429rem;
      text-align: left;
      color: rgba(0, 0, 0, 0.87);
      border-collapse: separate;
      border-spacing: 0;

      thead {
        box-shadow: none;

        tr {
          th {
            cursor: auto;
            background: #f9fafb;
            text-align: inherit;
            color: rgba(0, 0, 0, 0.87);
            padding: 0.92857143em 0.78571429em;
            vertical-align: inherit;
            font-style: none;
            font-weight: 700;
            text-transform: none;
            border-bottom: 1px solid rgba(34, 36, 38, 0.1);
            border-left: 1px solid rgba(34, 36, 38, 0.1);
          }
        }
      }

      tbody {
        tr {
          td {
            padding: 0.78571429em 0.78571429em;
            text-align: inherit;
            border-left: 1px solid rgba(34, 36, 38, 0.1);
            border-bottom: 1px solid rgba(34, 36, 38, 0.1);
          }
        }
      }
    }
  }
}
</style>